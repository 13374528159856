<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        {{ $t("managediscounts") }}
      </h3>
      <div class="card-toolbar">
        <vue3-json-excel
          :json-data="dataItemsShow"
          :fields="json_fields"
          type="xls"
          name="ข้อมูลdiscount model.xls"
          class="me-2"
        >
          <button
            class="btn btn-sm btn-light btn-success"
            style="font-size: 14px; font-weight: bold"
          >
            <span class="svg-icon svg-icon-2x dark">
              <inline-svg src="media/Excel.svg" />
              Download Excel
            </span>
          </button>
        </vue3-json-excel>
        <button
          v-if="userAuth.isCanCreate"
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >{{ $t("add") }}
        </button>
      </div>
    </div>

    <div class="card-body pt-3">
      <!-- searchbar -->
      <!-- <Search @textSearch="textSearch" /> -->
      <!-- searchbar -->

      <div class="table-responsive">
        <a-table :dataSource="dataItemsShow" :columns="columns">
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'no'">
              {{ $t("no") }}
            </template>
            <template v-if="column.dataIndex === 'code'">
              {{ $t("discountscode") }}
            </template>
            <template v-if="column.dataIndex === 'name'">
              {{ $t("discountsname") }}
            </template>
          </template>

          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'no'">
              {{ record.no }}
            </template>
            <template v-if="column.dataIndex === 'customer.code'">
              <span v-if="record.customer.code">
                {{ record.customer.code ? record.customer.code : "" }}
              </span>
            </template>
            <template v-if="column.dataIndex === 'customer.name'">
              <span v-if="record.customer.name">
                {{ record.customer.name ? record.customer.name : "" }}
              </span>
            </template>
            <template v-if="column.dataIndex === 'createdAt'">
              <span v-if="record.createdAt">
                {{ record.createdAt ? record.createdAt.substring(0, 10) : "" }}
              </span>
            </template>
            <template v-if="column.dataIndex === 'route'">
              <span v-if="record.route">
                {{ record.route ? record.route.code : "" }} |
                {{ record.route ? record.route.name : "" }}
              </span>
            </template>
            <template v-if="column.dataIndex === 'status'">
              <span v-if="record.status == 'ขายปกติ'" style="color: green">{{
                record.status
              }}</span>
              <span v-else style="color: red">{{ record.status }}</span>
            </template>
            <template v-if="column.dataIndex === 'action'">
              <div class="row">
                <div
                  class="col-sm-6 d-flex justify-content-md-end"
                  v-if="userAuth.isCanModified"
                >
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success p-0"
                    @click="goToEdit(record.id)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
                <div class="col-sm-6" v-if="userAuth.isCanDelete">
                  <button
                    @mouseenter="hoverBBtn"
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                    @click="goToDelete(record, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </div>
            </template>
          </template>
        </a-table>

        <!-- <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
            >
              <th>{{ $t("no") }}</th>
              <th>{{ $t("discountscode") }}</th>
              <th>{{ $t("discountsname") }}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>
                <input
                  type="checkbox"
                  @change="isChecked(item)"
                  :checked="checkAll ? true : false"
                />
              </td>
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>


              <td>
                <div class="row">
                  <div class="col-sm-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        p-0
                      "
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6 d-flex">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        p-0
                      "
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <!-- <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div> -->
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import promotionAPI from "@/api/promotion/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";
import userApi from "@/api/user/";
import { Decode } from "@/services";

export default {
  setup() {
    document.title = "CHOMTHANA | คลังสินค้า";
  },
  components: {
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  data: () => ({
    json_fields: {
      รหัสDiscountModel: "code",
      ชื่อDiscountModel: "name",
    },

    columns: [
      { title: "no", dataIndex: "no" },
      { title: "discountscode", dataIndex: "code" },
      { title: "discountsname", dataIndex: "name" },
      { title: "", dataIndex: "action" },
    ],

    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    checkAll: false,
    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    warehouse_type: "",
    is_sale_warehouse: "",
    allData: [],
    userAuth: [],
    userLocal: [],
    masterMenuId: 22,
  }),

  watch: {
    checkAll(val) {
      if (val) {
        this.checkbox = this.dataItemsShow;
        this.newCheckbox = this.dataItemsShow;
      } else {
        this.checkbox = [];
        this.newCheckbox = [];
      }
    },
    // searchInput(val) {
    //   if (this.oldSearchInput !== 0) {
    //     if (
    //       val.length - this.oldSearchInput === -1 ||
    //       val.length - this.oldSearchInput === 1
    //     ) {
    //       this.dataItemsShow = this.allData;
    //       this.tableItems = this.allData;
    //     } else if (val.length - this.oldSearchInput === -this.oldSearchInput) {
    //       this.dataItemsShow = this.allData;
    //       this.tableItems = this.allData;
    //     }
    //   }
    // },
  },

  created() {
    this.checkMenu();
    this.getAll();
    // this.dataItemsShow = [
    //   {
    //     code: "000001",
    //     name: "ส่วนลด 1",
    //   },
    // ];
    // this.tableItems = this.dataItemsShow;
    // this.allData = this.dataItemsShow;
  },
  methods: {
    async checkMenu() {
      this.userLocal = JSON.parse(
        Decode.decode(localStorage.getItem("user_temp"))
      );

      let responseData = [];
      try {
        responseData = await userApi.user.checkAuth(
          this.userLocal.userGroupId,
          this.masterMenuId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.userAuth = responseData.data[0];
        console.log("userAuth", this.userAuth);
        if (!this.userAuth.isCanView) {
          // Swal.fire({
          //   icon: "error",
          //   title: "ไม่มีสิทธิ์เข้าใช้งาน",
          //   text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          // });
          this.$router.push("/404");
        }
      } else {
      }
    },
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await promotionAPI.discount.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToNew() {
      // this.$router.push({ name: "amount-quoted-new" });
      this.$router.push({
        path: "/promotion/discount/new",
        query: {},
      });
    },
    goToEdit(id) {
      this.$router.push(`/promotion/discount/edit?id=${id}`);
    },

    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2delete")} ${item.name} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await promotionAPI.discount.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: `${this.$t("deletesuccessfull")} `,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
      };
      updateResponse = await whApi.warehouse.update(id, this.itemApprove);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    textSearch(val) {
      console.log({ val });
    },

    async search() {
      this.loading = true;

      const responseSearch = await whApi.warehouse.search({
        warehouse_type: this.warehouse_type,
        is_sale_warehouse: this.is_sale_warehouse,
        search: this.searchInput,
      });

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
